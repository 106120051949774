module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"OFSUPUTY"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
